.App {
  text-align: center;
  padding: 10;
  width: 100%;
}

.App-logo {
  height: 13vh;
  pointer-events: none;
  min-height: 175px;
}
/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /* background-color: #282c34; */
  background-color: #fff;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 25px;
  font-family: "Roboto", "Helvetica", "Times New Roman", Times, serif;
}

.App-link {
  color: #61dafb;
}

.selectCityList svg {
  width: 0.5em;
}

.listNum {
  width: 5%;
  display: inline-block;
  vertical-align: top;
}

.listTxt {
  width: 90%;
  display: inline-block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

[class*="Label"],
h6,
h4,
h3,
h2,
h1 {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

div.MuiContainer-root {
  padding: 0;
}

/* @media only screen and (max-width: 1028px) {
  label[data-shrink="false"] {
    display: none;
  }
} */

@media only screen and (max-width: 1303px) {
  /* label[data-shrink="false"] {
    display: none;
  } */
  div.longText label.MuiInputLabel-shrink {
    /* display: none; */
  }
  div label[data-shrink="true"]:not(.Mui-focused):nth-of-type(1) {
    display: none;
  }
  div label[data-shrink="true"].Mui-focused:nth-of-type(2) {
    display: none;
  }
  /* .MuiFormControl-root div, */
  /* label + .MuiInput-formControl {
    margin-top: 50px;
  } */
  label + .MuiInput-formControl {
    margin-top: 55px !important;
  }
  .MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top center;
  }

  header #google_translate_element {
    position: relative;
    right: auto;
    top: auto;
  }
}
#google_translate_element {
  position: absolute;
  right: 25px;
  top: 25px;
}

.oaklandButton {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New",
    monospace;
  font-family: "Source Sans Pro", sans-serif;
  box-sizing: border-box;
  text-decoration: none;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.175rem 0.55rem;
  line-height: 1.5;
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
  transition: all 0.5s;
  width: 100% !important;
  max-width: 250px;
  border-radius: 40px;
  font-size: 1.5em;
  border: none;
  flex-wrap: nowrap;
  background-color: #ff9e57;
  color: #340d69;
  background-image: none !important;
  cursor: pointer;
}

.oaklandButton:hover {
  background-color: #ff8329;
  color: #340d69;
}

@media screen and (max-width: 600px) {
  #AMI {
    position: absolute;
    left: 0;
    width: 100%
  }
}